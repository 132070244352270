import React, { useState, useEffect } from "react";
import { auth, db, crashlytics } from "./firebaseConfig";
import { signOut, getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import "./App.css";
import SurveyResults from "./SurveyResults";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory

function App() {
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [submissionData, setSubmissionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user] = useAuthState(auth);
  const navigate = useNavigate(); // Initialize navigate

  // Redirect if not logged in
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/login");
      } else {
        navigate("/app");
      }
    });
  }, [navigate]);

  //Logout user
  const logout = async () => {
    try {
      await signOut(auth);
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  //Logout with confirmation window
  const handleLogout = async () => {
    // Show a confirmation dialog
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    
    // If user confirms, proceed with logout
    if (confirmLogout) {
      await logout();
      navigate("/login"); // Redirect to login page after logging out
    }
  };

  // Load chat history and submission data from Firestore
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const chatDocRef = doc(db, "chatHistories", user.uid);
          const chatSnapshot = await getDoc(chatDocRef);
          if (chatSnapshot.exists()) {
            setChatHistory(chatSnapshot.data().chatHistory);
          }

          const submissionDocRef = doc(db, "submissions", user.uid);
          const submissionSnapshot = await getDoc(submissionDocRef);
          if (submissionSnapshot.exists()) {
            setSubmissionData(submissionSnapshot.data().submissionData);
          }
        } catch (err) {
          console.error("Error fetching data:", err);
          setError("Failed to load data.");
        }
      }
    };
    fetchData();
  }, [user]);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const handleAnalyzeResponses = async () => {
      setLoading(true);
      setError(null);

      try {
        const analysisPromises = submissionData.map(async (submission) => {
          // Wait 5 seconds before processing each submission
          await sleep(10000); // 5000 milliseconds = 5 seconds

          if (!submission.spreadsheetUrl) {
            console.error("No spreadsheet URL available.");
            return submission; // Return the submission as is if no spreadsheet URL
          }

          const analysisResponse = await fetch(
            "https://script.google.com/macros/s/AKfycbwtJ8LBaFYxCzgOS0CCpJFCj1lrsnu3t6l5mxWql-d7YcRTUqu5KMPNjXWSOGJOPyNjnA/exec",
            {
              method: "POST",
              headers: {
                "Content-Type": "text/plain",
              },
              body: JSON.stringify({
                spreadsheetUrl: submission.spreadsheetUrl,
              }),
            }
          );

          if (!analysisResponse.ok) {
            throw new Error("Failed to analyze responses");
          }

          const analysisData = await analysisResponse.json();
          return { ...submission, analysis: analysisData }; // Add analysis data to the submission
        });

        const results = await Promise.all(analysisPromises);
        setSubmissionData(results); // Update the submission data with the analysis results
      } catch (error) {
        console.error("Error during analysis:", error);
        setError("Error analyzing responses. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (submissionData.length > 0) {
      handleAnalyzeResponses(); // Trigger the analysis when submission data changes
    }
  }, [submissionData]);

  // Save chat history to Firestore
  useEffect(() => {
    const saveChatHistory = async () => {
      if (user && chatHistory.length) {
        try {
          const chatDocRef = doc(db, "chatHistories", user.uid);
          await setDoc(chatDocRef, { chatHistory }, { merge: true });
        } catch (err) {
          console.error("Error saving chat history:", err);
        }
      }
    };
    saveChatHistory();
  }, [chatHistory, user]);

  // Save submission data to Firestore
  useEffect(() => {
    const saveSubmissionData = async () => {
      if (user && submissionData.length) {
        try {
          const submissionDocRef = doc(db, "submissions", user.uid);
          await setDoc(submissionDocRef, { submissionData }, { merge: true });
        } catch (err) {
          console.error("Error saving submission data:", err);
        }
      }
    };
    saveSubmissionData();
  }, [submissionData, user]);

  const handleSendMessage = async () => {
    if (message.trim()) {
      const messageExists = chatHistory.some((msg) => msg.includes(message));

      if (!messageExists) {
        setChatHistory((prevHistory) => [...prevHistory, message]); // Update chat history
        setMessage("");

        try {
          const agentResponse = await fetch(
            "https://real-shepherd-excited.ngrok-free.app/api/call_agent",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": "true",
              },
              body: JSON.stringify({ business_description: message }),
            }
          );

          if (!agentResponse.ok) {
            throw new Error("Network response was not ok");
          }

          const agentData = await agentResponse.json();
          const { formTitle, questions, googlePlacesQuery } = agentData;

          const formResponse = await fetch(
            "https://real-shepherd-excited.ngrok-free.app/api/create_form",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": "true",
              },
              body: JSON.stringify({ form_title: formTitle, questions }),
            }
          );

          if (!formResponse.ok) {
            throw new Error("Failed to create Google Form");
          }

          const formResult = await formResponse.json();
          const formUserUrl = String(formResult.formUrl).replace(
            "edit",
            "viewform"
          );
          const newSpreadsheetUrl = formResult.spreadsheetUrl;

          setSubmissionData((prevData) => [
            ...prevData,
            { message, formTitle, spreadsheetUrl: newSpreadsheetUrl },
          ]);

          const emailsResponse = await fetch(
            "https://real-shepherd-excited.ngrok-free.app/api/get_business_emails",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": "true",
              },
              body: JSON.stringify({ googlePlacesQuery }),
            }
          );

          if (!emailsResponse.ok) {
            throw new Error("Failed to fetch business emails");
          }

          const emails = await emailsResponse.json();

          const emailResponse = await fetch(
            "https://real-shepherd-excited.ngrok-free.app/api/send_email",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": "true",
              },
              body: JSON.stringify({
                recipients: emails,
                formUrl: formUserUrl,
              }),
            }
          );

          if (!emailResponse.ok) {
            throw new Error("Failed to send emails");
          }

          const emailResult = await emailResponse.json();
          console.log(emailResult);
        } catch (error) {
          console.error("Error:", error);
          setError("There was an error processing your request."); // Update error state
        }
      } else {
        console.log("This description has already been processed.");
      }
    }
  };

  return (
    <div className="App">
      <h1 className="app-title">
        <span className="pro">P</span>
        <span className="pro-small">ro</span>
        <span className="val">Val</span>
      </h1>
      <div className="chat-window">
        <div className="messages">
          {chatHistory.map((msg, index) => (
            <div key={index} className="message">
              {msg}
            </div>
          ))}
        </div>
        <div className="input-area">
        <button onClick={handleLogout}>Sign out</button>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type a business description..."
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
      <div>
        <h2>Submissions</h2>
        {submissionData.length > 0 ? (
          submissionData.map((submission, index) =>
            submission.analysis ? (
              <SurveyResults
                key={index}
                analysisResults={submission.analysis}
                formTitle={submission.formTitle}
              />
            ) : null
          )
        ) : (
          <p>No submissions yet.</p>
        )}
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}{" "}
      {/* Display error message */}
    </div>
  );
}

export default App;
