// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZWCJaY6OltuwMVdW6hQhPAScefpSrpy0",
  authDomain: "proval-10ef6.firebaseapp.com",
  projectId: "proval-10ef6",
  storageBucket: "proval-10ef6.appspot.com",
  messagingSenderId: "855057002382",
  appId: "1:855057002382:web:154764fd753d67af66bedf",
  measurementId: "G-JF2RCE07QQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();